var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl-py-3 gl-mb-4 gl-px-4 gl-rounded-lg gl-line-height-20 gl-word-break-word ai-genie-chat-message",class:{
    'gl-ml-auto gl-bg-blue-100 gl-text-blue-900 gl-rounded-bottom-right-none': _vm.isUserMessage(
      _vm.message,
    ),
    'gl-rounded-bottom-left-none gl-text-gray-900 gl-bg-white gl-border-1 gl-border-solid gl-border-gray-50': _vm.isAssistantMessage(
      _vm.message,
    ),
  }},[_c('div',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.messageContent),expression:"messageContent"}],ref:"content"}),_vm._v(" "),(_vm.isAssistantMessage(_vm.message))?_vm._t("feedback",null,{"promptLocation":_vm.promptLocation,"message":_vm.message}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }